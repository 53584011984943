<template>
  <div class="topbar-item">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
      <div class="d-flex flex-column text-right pr-3">
        <span class="text-muted font-weight-bold font-size-base d-none d-md-inline">{{ User.UserName }}</span>
        <span class="text-dark-75 font-weight-bolder font-size-base d-none d-md-inline"
          ><i class="fas fa-sync-alt icon-sm mr-2" v-if="User.ClientID !== User.InitialClientID && User.InitialClientID !== undefined"></i>
          {{ User.ClientName }}</span
        >
      </div>
      <span v-for="(role, index) in getMainRoleTypes(User.MainRole)" :key="index" class="symbol symbol-40 mr-3" :class="[`symbol-light-${role.class}`]">
        <span class="symbol-label font-size-h5 font-weight-bolder">{{ $mainFunctions.getFirstLetters(User.ClientName) }}</span>
      </span>
    </div>

    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          <translate>User Profile</translate>
        </h3>
        <a class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <!--begin::Symbol-->
          <span
            v-for="(role, index) in getMainRoleTypes(User.MainRole)"
            :key="index"
            class="symbol symbol-100 mr-5"
            :class="[`symbol-light-${role.class}`]"
            :title="showPopovers ? $gettext('My role') : null"
            v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
          >
            <span class="symbol-label font-size-h1 font-weight-bolder">{{ $mainFunctions.getFirstLetters(User.ClientName) }}</span>
            <i class="symbol-badge bg-success"></i>
          </span>
          <!--end::Symbol-->

          <div class="d-flex flex-column">
            <!-- <router-link
              :to="{ name: 'route.partners.edit', params: { PartnerID: User.ClientID } }"
              class="font-weight-bold font-size-h3 text-dark-75 text-hover-primary"
            >
              {{ User.UserName }}
            </router-link> -->
            <h3 class="font-weight-bolder text-dark-75">{{ User.UserName }}</h3>
            <div class="text-muted font-weight-bolder mt-1">{{ User.ClientName }}</div>
          </div>
        </div>

        <!--begin::Language-->
        <div v-show="showDataDebug">
          <div class="separator separator-dashed mt-8 mb-5"></div>

          <h6 class="text-muted" v-translate>Choose language</h6>
          <b-form-select name="language" v-model="$language.current" class="mt-3">
            <option v-for="(language, key) in $language.available" :value="key" :key="key">
              {{ language }}
            </option>
          </b-form-select>
        </div>
        <!--end::Language-->

        <div class="separator separator-dashed my-7"></div>

        <div class="d-flex align-items-center flex-wrap">
          <!--begin: Item-->

          <label class="option w-100" :class="User.MainRole !== undefined ? `bg-${getMainRoleType(User.MainRole, 'class')}` : ''">
            <span class="option-label text-white">
              <span class="option-head justify-content-start">
                <span class="svg-icon svg-icon-3x mr-2 svg-icon-white mr-4">
                  <!--begin::Svg Icon -->
                  <inline-svg :src="getMainRoleType(User.MainRole, 'icon')"></inline-svg>
                  <!--end::Svg Icon-->
                </span>

                <span class="d-flex flex-column">
                  <span class="font-weight-bolder font-size-sm" v-translate>My role</span>

                  <span class="option-title font-size-h4 font-weight-bolder w-100 text-white">{{
                    $gettext(typeof User.MainRole !== "undefined" ? getMainRoleType(User.MainRole, "name") : "—") | safePrint
                  }}</span>
                </span>
              </span>
            </span>
          </label>

          <!--end: Item-->
          <!--begin: Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 my-1" v-if="false">
            <span class="svg-icon svg-icon-3x svg-icon-opalean-gray-medium mr-4">
              <!--begin::Svg Icon -->
              <inline-svg src="media/svg/icons/Communication/Address-card.svg"> </inline-svg>
              <!--end::Svg Icon-->
            </span>

            <div class="d-flex flex-column text-dark-75 d-none">
              <span class="font-weight-bolder font-size-sm">Group A</span>
              <span class="text-dark-50 font-weight-bolder font-size-h5">
                <!-- — = class:text-dark-50-->
                {{ User.GroupA !== undefined ? User.GroupA : "—" }}</span
              >
            </div>
          </div>
          <!--end: Item-->
        </div>
        <!--end::Header-->

        <div class="separator separator-dashed my-7"></div>

        <!--begin::Details-->
        <div>
          <h6 class="mb-5 text-muted" v-translate>Client details</h6>
          <div class="mb-7">
            <div class="d-flex justify-content-between align-items-center mb-2" v-if="User.UserEMail">
              <span class="text-dark-75 font-weight-bolder mr-2" v-translate>Email</span>
              <a :href="`mailto:${User.UserEMail}`" class="text-dark text-hover-primary">{{ User.UserEMail }}</a>
            </div>
            <div class="d-flex justify-content-between align-items-center my-2" v-if="User.Address1">
              <span class="text-dark-75 font-weight-bolder mr-2" v-translate>Address</span>
              <span class="text-hover-primary text-right">{{ User.Address1 }} {{ User.Address2 }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center my-2" v-if="User.ZipCode">
              <span class="text-dark-75 font-weight-bolder mr-2" v-translate>Zip code</span>
              <span class="text-hover-primary">{{ User.ZipCode }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center my-2" v-if="User.City">
              <span class="text-dark-75 font-weight-bolder mr-2" v-translate>City</span>
              <span class="text-hover-primary">{{ User.City }}</span>
            </div>
            <div class="d-flex justify-content-between align-items-center my-2" v-if="User.CountryISO">
              <span class="text-dark-75 font-weight-bolder mr-2" v-translate>Country</span>
              <span class="text-hover-primary">{{ User.CountryISO }}</span>
            </div>
          </div>
        </div>
        <!--end::Details-->

        <div class="separator separator-dashed mt-8 mb-5"></div>

        <!--begin::Switch AuthorizedClients -->
        <div v-if="User.ClientID !== User.InitialClientID && User.InitialClientID !== undefined" class="mb-5">
          <h6 class="text-primary" v-translate>Switched current client profile</h6>
          <span class="text-dark-75 font-weight-bolder font-size-base d-none d-md-inline"
            ><i class="fas fa-sync-alt icon-sm mr-2"></i> {{ User.InitialClientName }}</span
          >

          <!--begin::Switch-->
          <button class="btn btn-secondary btn-bold btn-block font-weight-bold mt-2 mb-2" @click="onSwitch({ ClientID: User.InitialClientID })">
            <translate>Back to main profile</translate>
          </button>
          <!--end::Switch-->
        </div>

        <div v-if="computedAuthorizedClients !== undefined && computedAuthorizedClients.length && !isMobileDevice" class="mb-5">
          <h6 class="text-muted" v-translate>Switch client profile</h6>
          <!-- <b-form-select v-model="selectedAuthorizedClients" :options="computedAuthorizedClients" value-field="ClientID" text-field="ClientName" class="mt-3">
            <template #first>
              <b-form-select-option value="null" disabled v-translate>Switch to client profile ...</b-form-select-option>
            </template>
          </b-form-select> -->

          <div class="form-group mt-3">
            <!-- :selectLabel="$gettext('Press enter to select')"-->
            <Multiselect
              id="alert-item-email"
              v-model="selectedAuthorizedClients"
              selectLabel=""
              :selectedLabel="$gettext('Selected')"
              :placeholder="$gettext('Choose client profile ...')"
              :options="filteredAuthorizedClients != null ? filteredAuthorizedClients : computedAuthorizedClients"
              track-by="ClientID"
              label="ClientName"
              :internal-search="false"
              @search-change="ignoreAccents"
            ></Multiselect>
          </div>
        </div>
        <!--end::Switch AuthorizedClients -->

        <!--begin::Switch-->
        <button
          class="btn btn-primary btn-bold btn-block font-weight-bold mt-2"
          @click="onSwitch()"
          v-if="selectedAuthorizedClients !== null && !isMobileDevice"
        >
          <translate>Switch profile</translate>
        </button>
        <!--end::Switch-->

        <div class="separator separator-dashed my-7"></div>

        <!--begin::SignOut-->
        <button class="btn btn-outline-primary btn-bold btn-block font-weight-bold mt-2" @click="onLogout">
          <translate>Sign out</translate>
        </button>
        <!--end::SignOut-->

        <!-- DEV -->
        <b-card class="card-custom gutter-b mt-10" body-class="p-3" v-if="showDataDebug">
          <pre>{{ $language.current }}</pre>
          <pre>{{ $language.available }}</pre>
          <pre>{{ User.Language }}</pre>
          <pre>User: {{ User }}</pre>
        </b-card>
      </perfect-scrollbar>
      <!--end::Content-->

      <p class="text-right text-muted">
        <small><strong>Opatrace</strong> | Version {{ $store.getters.appVersion }} </small>
        <span class="label label-inline label-warning mb-1 mr-1 ml-1" v-if="$store.getters.apiBaseURL != defautApiBaseURL()"
          ><strong class="mr-1">API</strong> {{ $store.getters.apiBaseURL }}</span
        >
        <span class="label label-inline label-primary mb-1 mr-1 ml-1" v-if="isMobileDevice"> <strong>Mobile</strong></span>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapGetters, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
//import Swal from "sweetalert2";

import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

export default {
  name: "QuickUser",
  components: {
    Multiselect,
  },
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,
      showPopovers: statics.showPopovers,
      // Statics
      partnerMainRoleTypes: statics.partnerMainRoleTypes,
      // Model
      selectedAuthorizedClients: null,

      // Search
      filteredAuthorizedClients: null,
    };
  },
  mounted() {
    console.info("QuickUser.vue");
    if (this.isAuthenticated) {
      // Init Quick User Panel
      KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

      // First load
      // Get User
      this.$store.dispatch("loadUser").then(() => {
        console.log("loadUser");
      });
    }
  },
  mixins: [helpers],
  methods: {
    ...mapMutations(["setDashboardSettings"]),
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        ApiService.redirectToDisconnect();
      });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    defautApiBaseURL() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    async onSwitch(Client = this.selectedAuthorizedClients) {
      console.log("onSwitch::", Client, Client.ClientID, this.$router.currentRoute.path, this.$router.currentRoute.name);

      // Wait
      if (
        this.$router.currentRoute.name === "route.operations.create" ||
        this.$router.currentRoute.name === "route.operations.edit" ||
        this.$router.currentRoute.name === "route.partners.create" ||
        this.$router.currentRoute.name === "route.partners.edit"
      ) {
        console.log("onSwitch:: Wait !");

        // First route to dashboard
        const goneDashboard = await this.$router.push({ name: "route.dashboard" }); //https://stackoverflow.com/questions/42299594/await-is-a-reserved-word-error-inside-async-function

        if (goneDashboard) {
          // navigation prevented
          console.log("onSwitch:: Waited success #1 routed to dashboard");
        }
      }

      // Continue
      // Load mode
      // show page loading
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      // Switch to new user
      ApiService.get("/changeclient", "id=" + Client.ClientID)
        .then(() => {
          // Reminder > Data order
          // user
          // partners
          // pallets
          // countries
          // dashboard
          // notifications
          // notificationscount
          // operation...

          // Reset dashboard before reload to avoid settings comparaison
          this.setDashboardSettings([]);

          // Then reload datas
          // Get User >> duplicated to avoid no page change on dashboard
          this.$store.dispatch("loadUser").then(() => {
            console.log("loadUser");
          });

          // Load Partners
          this.$store.dispatch("loadPartners").then(() => {
            console.log("loadPartners");
          });

          // Load Pallets
          this.$store.dispatch("loadPallets").then(() => {
            console.log("loadPallets");
          });

          // No reload
          // // Load Countries
          // this.$store.dispatch("loadCountries").then(() => {
          //   console.log("loadCountries");
          // });

          // Reload
          // Get Dashboard >> duplicated to avoid no page change on dashboard
          this.$store.dispatch("loadDashboard").then(() => {
            console.log("loadDashboard");
          });

          // To remove
          // Load Balances
          // this.$store.dispatch("loadBalances").then(() => {
          //   console.log("loadBalances");
          // });

          // To remove
          // Load Reports
          // this.$store.dispatch("loadReports").then(() => {
          //   console.log("loadReports");
          // });

          setTimeout(() => {
            // Remove page loader after some time
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

            // Remove switched v-model value
            this.selectedAuthorizedClients = null;

            // Close canvas
            new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();

            // Then route to dashboard ( only if ≠ of current dashbord page )
            if (this.$router.currentRoute.name !== "route.dashboard") this.$router.push({ name: "route.dashboard" });
          }, 1000);
        })
        .catch(({ response }) => {
          response = response === undefined ? "[SWITCH] Error, cannot reach the server or get the user" : response.data.errors;
          window.swalError.fire({
            title: "",
            text: response,
            icon: "error",
            //confirmButtonClass: "btn btn-secondary",
            //heightAuto: false,
          });
          // Remove page loader after some time
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    // Fix Multiselect search without Diacritics ( accents )
    /* inspired by https://github.com/shentao/vue-multiselect/issues/281#issuecomment-597718858 */
    /* inside map, this refer to Window and not App */
    /* https://stackoverflow.com/questions/46371302/map-unable-to-access-objects-this-function */
    ignoreAccents(search) {
      const needle = _.lowerCase(_.deburr(search));

      // console.log(
      //   "ignoreAccents::",
      //   search,
      //   search.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
      //   needle,
      //   this.computedAuthorizedClients,
      //   this.computedAuthorizedClients.filter(
      //     (c) => _.lowerCase(_.deburr(c.ClientName)).includes(needle) || _.lowerCase(_.deburr(c.ClientCity)).includes(needle)
      //   )
      // );

      this.filteredAuthorizedClients = search
        ? this.computedAuthorizedClients.filter(
            (c) => _.lowerCase(_.deburr(c.ClientName)).includes(needle) || _.lowerCase(_.deburr(c.ClientCity)).includes(needle)
          )
        : this.computedAuthorizedClients;
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getDevice", "getUser"]),
    User: function () {
      return this.getUser;
    },
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    computedAuthorizedClients() {
      if (typeof this.User.AuthorizedClients !== "undefined")
        return _.orderBy(
          this.User.AuthorizedClients.filter((c) => c.ClientID !== this.User.ClientID),
          "ClientName"
        );
      else return [];
    },

    isMobileDevice() {
      return this.getDevice === "mobile" ? true : false;
    },
  },
  watch: {
    User: function (newUser, oldUser) {
      console.log("Watch : this.$store.state.User", this);
      console.log("OLD : " + oldUser.length);
      console.log("NEW : " + newUser.length);
      console.log("Language current : " + this.$language.current);
      console.log("Language currentKebabCase : " + this.$language.currentKebabCase);
      console.log("Language currentLang : " + this.$language.currentLang);
      console.log("User Language : " + newUser.Language);

      // Change langage when received User preferences
      // https://github.com/Polyconseil/vue-gettext#vueconfiglanguage
      if (newUser.Language) this.$language.current = newUser.Language;

      // https://michaelnthiessen.com/force-re-render/
    },
  },
};
</script>
