<template>
  <div>
    <div class="d-flex flex-column pt-12 pb-12 bgi-size-cover bgi-no-repeat rounded-top" :style="{ backgroundImage: `url(${backgroundImage})` }">
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">User Notifications</span>
        <span
          class="btn btn-text btn-opalean-info btn-sm font-weight-bolder btn-font-md ml-2"
          v-if="NotificationsCount != 0 && NotificationsCount != undefined"
        >
          {{ NotificationsCount }} <translate>new</translate>
        </span>
        <!-- <span
          class="
            btn btn-text btn-outline-opalean-whiter btn-sm
            font-weight-bolder
            btn-font-md
            ml-2
          "
          v-if="NotificationsCount != 0 && NotificationsCount != undefined"
        >
          <translate>Mark all as read</translate>
        </span>
        -->
      </h4>
    </div>

    <perfect-scrollbar class="scroll" style="max-height: 40vh; position: relative" v-if="NotificationsCount != 0 && NotificationsCount != undefined">
      <template v-for="(Notification, index) in Notifications.Notifications">
        <!--begin::Item-->
        <div class="d-flex align-items-center bg-hover-opalean-white pt-5 pb-3 pl-8 pr-7 mr-n7" :key="index">
          <!--begin::Text-->
          <router-link
            :to="{
              name: 'route.operations.edit',
              params: {
                OperationUID: Notification.OperationUID,
              },
            }"
            class="mb-1 --stretched-link"
            @click.native="_loadNotifications()"
          >
            <div class="d-flex flex-column font-weight-bold">
              <span>
                <!-- Partner icon -->
                <span
                  v-for="(role, index) in getMainRoleTypes(
                    Notification.PartnerID !== -1
                      ? typeof getPartnerByPartnerID(Notification.PartnerID) !== 'undefined'
                        ? getPartnerByPartnerID(Notification.PartnerID).MainRole
                        : null
                      : null
                  )"
                  :key="index"
                  class="symbol symbol-40 mr-3"
                  :class="[`symbol-light-${role.class}`]"
                  :title="showPopovers ? $gettext('Partner role') : null"
                  v-b-popover.hover.html="showPopovers ? `<h4 class='font-weight-bolder text-${role.class}'>${role.name}</h4> ${role.description}` : null"
                >
                  <span class="symbol-label font-size-h5 font-weight-bolder">{{
                    $mainFunctions.getFirstLetters(
                      typeof getPartnerByPartnerID(Notification.PartnerID) !== "undefined" ? getPartnerByPartnerID(Notification.PartnerID).Name : "-"
                    )
                  }}</span>
                </span>

                <!-- Partner Name -->
                <span class="text-dark --text-hover-primary mb-1 font-size-lg font-weight-bolder">
                  {{ getPartnerByPartnerID(Notification.PartnerID) | getSafeObjectValue("Name") }} <span class="ml-2 mr-2">›</span>
                </span>

                <!-- Operation ID -->
                <span class="label label-inline font-weight-bold mr-1 label-primary">{{ Notification.OperationUID | safePrint }}</span>
              </span>

              <!-- <span class="text-muted">
              {{ Notification.MessageText }}
            </span> -->

              <!-- <span class="text-muted font-size-xs">
              {{ Notification.MessageDateTime | getDateFormat("LocaleDate") }}
            </span> -->

              <!--  Datetime -->
              <div class="d-flex flex-row text-muted font-size-xs mt-1">
                <span v-b-toggle="'collapse' + index" class="font-weight-bold" v-if="$language.current === 'en_EN'"
                  >{{ Notification.ModificationDate | getDateFormat("Humanized") }} <translate>ago</translate></span
                >
                <span v-b-toggle="'collapse' + index" class="font-weight-bold" v-else
                  ><translate>ago</translate> {{ Notification.ModificationDate | getDateFormat("Humanized", $language.current) }}</span
                >
                <b-collapse :id="'collapse' + index" class="ml-2">
                  <span
                    >{{ Notification.ModificationDate | getDateFormat("LocaleDate") }}, {{ Notification.ModificationDate | getDateFormat("LocaleTime") }}</span
                  >
                </b-collapse>
              </div>
            </div>
          </router-link>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </template>
    </perfect-scrollbar>

    <div class="d-flex align-items-center pt-12 pb-12" v-else>
      <span class="d-flex align-items-center w-50 m-auto"
        ><span class="svg-icon svg-icon-3x mr-4 svg-icon-opalean-gray-medium">
          <inline-svg src="media/svg/icons/Communication/Readed-mail.svg" />
        </span>
        <span class="text-opalean-gray-medium"
          ><strong><translate>You are up to date !</translate></strong> <translate>there are no unread notifications</translate></span
        >
      </span>
    </div>

    <!-- DEV -->
    <b-card class="card-custom gutter-b mt-10" body-class="p-3" v-if="showDataDebug">
      <pre>Notifications: {{ Notifications }}</pre>
    </b-card>
  </div>
</template>

<script>
import statics from "@/core/statics/statics.js";
import helpers from "@/core/statics/helpers.js";

import { mapGetters } from "vuex";

import ApiService from "@/core/services/api.service";

export default {
  name: "DropdownNotification",
  data() {
    return {
      // Preferences
      showDataDebug: statics.showDataDebug,
      showPopovers: statics.showPopovers,
      //
      Notifications: [],
      NotificationsCount: 0,
      timerNotifications: "",
    };
  },
  mixins: [helpers],
  //methods: {},
  computed: {
    ...mapGetters(["getPartnerByPartnerID"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
  },
  created() {
    this.loadNotifications();
    this.timerNotifications = setInterval(this.loadNotifications, 120000); //2=120000 3=180000 5=300000
  },
  methods: {
    loadNotifications() {
      // Notifications
      ApiService.get("/notifications")
        .then((response) => {
          this.Notifications = response.data;
        })
        .catch(function (error) {
          console.log("/notifications::load error no response" + JSON.stringify(error));
        });
      // Count
      ApiService.get("/notificationscount")
        .then((response) => {
          this.NotificationsCount = response.data.Count;
        })
        .catch(function (error) {
          console.log("/notificationscount::load error no response" + JSON.stringify(error));
        });
    },
    _loadNotifications() {
      console.log("_loadNotifications:: timeout... /notifications");
      setTimeout(() => {
        this.loadNotifications();
      }, 2000);
    },
    cancelInterval() {
      clearInterval(this.timerNotifications);
    },
  },
  beforeDestroy() {
    this.cancelInterval();
  },
  //   mounted() {
  //   // Notifications
  //   ApiService.get("/notifications")
  //     .then((response) => {
  //       this.Notifications = response.data;
  //     })
  //     .catch(function (error) {
  //       console.log("/notifications::load error no response" + JSON.stringify(error));
  //     });
  //   // Count
  //   ApiService.get("/notificationscount")
  //     .then((response) => {
  //       this.NotificationsCount = response.data.Count;
  //     })
  //     .catch(function (error) {
  //       console.log("/notificationscount::load error no response" + JSON.stringify(error));
  //     });
  // },
};
</script>
